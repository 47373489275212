<template lang="pug">
.main-wrapper
    header.main-wrapper-header
        .p-grid.p-align-center
            .p-col-3
                Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
            .p-col-9.ta-right
                h1.text-header.text-secondary MedClub / <b>Relatórios</b>

    ProgressBar(v-if='waiting' mode="indeterminate")
    Panel.my-2(v-else header='Gerar relatório')
        form.p-grid.p-fluid.p-justify-center(@submit.prevent='onFormSubmit()')
            .p-col-12.p-md-6
                .my-1
                    label.form-label Tipo de relatório:
                    Dropdown(
                        v-model='model.cd_relatorio'
                        :options='options.tiporelatorio'
                        optionLabel='text'
                        :filter='true'
                        optionValue='value'
                        dataKey='value'
                        @change='resetModel()'
                        @input="carregarFiltros"
                    )

                .my-1(v-if="[37,28].includes(model.cd_relatorio)")
                    label.form-label Sala(s):
                    ProgressBar(v-if='waitingUnidades' mode="indeterminate")
                    MultiSelect(
                        v-else
                        v-model='model.cd_unidade_list'
                        :options='options.unidades_medclub'
                        optionLabel='text'
                        :filter='true'
                        optionValue='value'
                        dataKey='value'
                    )
                        template(#option='props')
                            span <b> Sala: {{ props.option.text }} </b> <br>
                                span <b> Setor: </b> {{ props.option.textAlt1 }} / <b> Unidade: </b> {{ props.option.textAlt2 }}


                .my-1(v-if="[6].includes(model.cd_relatorio)")
                    label.form-label Estabelecimento:
                    ProgressBar(v-if='waiting_filiais' mode="indeterminate")
                    Dropdown(
                        v-else
                        v-model='model.cd_estabelecimento'
                        :options='options.filiaisMedclub'
                        optionLabel='text'
                        :filter='true'
                        optionValue='value'
                        dataKey='value'
                        @change='getEspecialistas()'
                    )

                .my-1(v-if="[33].includes(model.cd_relatorio)")
                    label.form-label Status do Pagamento:
                    Dropdown(
                        v-model='model.ie_status_pagamento'
                        :options='options.statusPagamento'
                        optionLabel='text'
                        :filter='true'
                        optionValue='value'
                        dataKey='value'
                        @change='getEspecialistas()'
                    )

                .my-1(v-if="[40].includes(model.cd_relatorio)")
                    label.form-label Status do Pagamento:
                    Dropdown(
                        v-model='model.ie_status_pagamento'
                        :options='options.statusPagamento'
                        optionLabel='text'
                        :filter='true'
                        optionValue='value'
                        dataKey='value'
                    )

                .my-1(v-if="[1, 34].includes(model.cd_relatorio)")
                    label.form-label Formato:
                        .p-inputgroup
                            SelectButton(
                                :options='options.formato'
                                optionLabel='label'
                                optionValue='value'
                                v-model='model.sintetico'
                            )

                .my-1(v-if="[1, 34].includes(model.cd_relatorio)")
                    label.form-label Totalizador:
                        .p-inputgroup
                            SelectButton(
                                :options='options.totalizadores'
                                optionLabel='label'
                                optionValue='value'
                                v-model='model.totalizador'
                            )

                .my-1(v-if="[2,3,5,7,9,11,12,26,31].includes(model.cd_relatorio)")
                    label.form-label Estabelecimento:
                    ProgressBar(v-if='waiting_estabelecimento' mode="indeterminate")
                    Dropdown(
                        v-else
                        v-model='model.cd_estabelecimento'
                        :options='options.estabelecimentos'
                        optionLabel='text'
                        :filter='true'
                        optionValue='value'
                        dataKey='value'
                        @change='getEspecialistas()'
                    )

                .my-1(v-if='[44,49].includes(model.cd_relatorio)')
                    label.form-label Empresa:
                    MultiSelect( v-model='model.cd_empresa' :options='options.cd_empresa'
                        optionLabel='label' optionValue='value' @change="getCorrentista()")

                .my-1(v-if='[51,52,53].includes(model.cd_relatorio)')
                    label.form-label Selecione uma Empresa:
                    Dropdown( v-model='model.cd_empresa' :options='options.cd_empresa'
                        optionLabel='label' optionValue='value' @change="getFaixasEtarias()")

                .my-1(v-if="[51,52].includes(model.cd_relatorio)")
                    label.form-label Situação do Aporte:
                    ProgressBar(v-if='waitingDominios' mode="indeterminate")
                    Dropdown(
                        v-model='model.situacao_aporte'
                        :options='options.situacao_aporte'
                        :showClear='true'
                        optionLabel='text'
                        optionValue='value'
                        dataKey='value'
                    )

                .my-1(v-if="[53].includes(model.cd_relatorio)")
                    label.form-label Faixa Etária:
                    ProgressBar(v-if='waitingFaixasEtarias' mode="indeterminate")
                    Dropdown(v-else
                        :disabled="!this.options.faixas_etarias.length > 0"
                        v-model='model.faixas_etarias'
                        :options='options.faixas_etarias'
                        :showClear='true'
                        optionLabel='text'
                        optionValue='value'
                        dataKey='value'
                    )

                .my-1(v-if='[44].includes(model.cd_relatorio)')
                    ProgressBar(v-if='waitinCorrentista' mode="indeterminate")
                    label.form-label(v-if='!waitinCorrentista') Correntista:
                    MultiSelect(v-if='!waitinCorrentista' v-model='model.cd_pessoa_fisica' :options='options.cd_pessoa_fisica'
                        optionLabel='label' optionValue='value' :disabled='!model.cd_empresa.length')

                .my-1(v-if="[1,14,18,20,27,33,44].includes(model.cd_relatorio)")
                    label.form-label Estabelecimento:
                    ProgressBar(v-if='waiting_estabelecimento' mode="indeterminate")
                    MultiSelect(
                        v-else
                        v-model='model.cd_estabelecimento_list'
                        :options='options.estabelecimentos_multi'
                        :filter='true'
                        optionLabel='text'
                        optionValue='value'
                        dataKey='value'
                        :showClear='true'
                        )

                .my-1(v-if="[1,26].includes(model.cd_relatorio) && !model.sintetico")
                    label.form-label Especialista:
                    ProgressBar(v-if='waitingEspecialistas' mode="indeterminate")
                    Dropdown(
                        v-else
                        v-model='model.cd_especialista'
                        :options='options.especialistasApp'
                        :filter='true'
                        optionLabel='text'
                        optionValue='value'
                        dataKey='value'
                    )

                .my-1(v-if="[26].includes(model.cd_relatorio)")
                    label.form-label Pergunta:
                    ProgressBar(v-if='waiting_perguntas' mode="indeterminate")
                    Dropdown(
                        v-else
                        v-model='model.cd_pergunta'
                        :options='options.perguntas'
                        :filter='true'
                        optionLabel='text'
                        optionValue='value'
                        dataKey='value'
                    )

                .my-1(v-if="[6,7,11,12].includes(model.cd_relatorio)")
                    label.form-label Especialista:
                    ProgressBar(v-if='waiting_especialistas' mode="indeterminate")
                    Dropdown(
                        v-else
                        v-model='model.cd_especialista'
                        :options='options.especialistas'
                        :filter='true'
                        optionLabel='text'
                        optionValue='value'
                        dataKey='value'
                        :showClear='true'
                    )

                .my-1(v-if="[1].includes(model.cd_relatorio)")
                    label.form-label Especialidade:
                    ProgressBar(v-if='waitingEspecialidades' mode="indeterminate")
                    MultiSelect(
                        v-else
                        v-model='model.cd_especialidade'
                        :options='options.cd_especialidade'
                        :filter='true'
                        optionLabel='text'
                        optionValue='value'
                        dataKey='value'
                        :showClear='true'
                    )

                .my-1(v-if="[8].includes(model.cd_relatorio)")
                    label.form-label Tipo de Procedimento:
                    Dropdown(
                        v-model='model.ie_tipo_procedimento'
                        :options='options.tipoProcedimento'
                        :filter='true'
                        optionLabel='text'
                        optionValue='value'
                        dataKey='value'
                    )
                .my-1(v-if="[2,3,8,11,12].includes(model.cd_relatorio)")
                    label.form-label Tipo Agenda:
                    Dropdown(
                        v-model='model.ie_tipo_agenda'
                        :options='options.tipoAgenda'
                        :filter='true'
                        optionLabel='text'
                        optionValue='value'
                        dataKey='value'
                        @change='getProcedimentos()'
                        :showClear='true'
                    )
                .my-1(v-if="[9].includes(model.cd_relatorio)")
                    label.form-label Procedimento:
                    ProgressBar(v-if='waitingProcedimentos' mode="indeterminate")
                    p.ta-center(v-else-if='! options.procedimentos.length' style='font-size: 12px;') <b>Sem procedimento disponível.</b>
                    Dropdown(
                        v-else
                        ref='dropdownProcedimentos'
                        :filter='true'
                        v-model='model.cd_procedimento'
                        :options='options.procedimentos'
                        optionValue='value'
                        optionLabel='text'
                        dataKey='id'
                        placeholder='- Selecione -'
                    )
                        template(#option='props')
                            span <b>{{ props.option.textAlt }}</b><br>
                            span {{ props.option.nm_procedimento }}

                .my-1(v-if="[10].includes(model.cd_relatorio)")
                    label.form-label Estado:
                    Dropdown(
                        v-model='model.cd_estado'
                        :options='options.estados'
                        :filter='true'
                        optionLabel='nm_estado'
                        optionValue='id'
                        dataKey='id'
                        @change='getCidades()'
                    )
                .my-1(v-if="[10].includes(model.cd_relatorio)")
                    label.form-label Cidade:
                    Dropdown(
                        v-model='model.cd_cidade'
                        :options='options.cidades'
                        :filter='true'
                        showClear=true
                        optionLabel='text'
                        optionValue='value'
                        dataKey='value'
                    )
                .my-1(v-if="[5,49].includes(model.cd_relatorio)")
                    label.form-label Situação:
                    Dropdown(
                        v-model='model.ie_status'
                        :options='options.situacao'
                        optionLabel='text'
                        optionValue='value'
                        dataKey='value'
                    )
                .my-1(v-if="[36].includes(model.cd_relatorio)")
                    label.form-label Tipo de Estorno:
                    ProgressBar(v-if='waitingDominios' mode="indeterminate")
                    Dropdown(
                        v-model='model.tipo_estorno'
                        :options='options.tipo_estorno'
                        :showClear='true'
                        optionLabel='text'
                        optionValue='value'
                        dataKey='value'
                    )
                .my-1(v-if="[36].includes(model.cd_relatorio)")
                    label.form-label Situação:
                    MultiSelect(
                        v-model='model.ie_situacao_list'
                        :options='options.situacao_estorno'
                        optionLabel='text'
                        optionValue='value'
                        dataKey='value'
                    )
                .my-1(v-if="[2,3].includes(model.cd_relatorio)")
                    label.form-label Situação:
                    Dropdown(
                        v-model='model.ie_status'
                        :options='options.choiceBloqueios'
                        optionLabel='text'
                        optionValue='value'
                        dataKey='value'
                        :showClear='true'
                    )
                .my-1(v-if='[].includes(model.cd_relatorio)')
                    label.form-label Situação Orçamento:
                    ProgressBar(v-if='waitingOrcamentoSituacao' mode="indeterminate")
                    Dropdown(
                        v-else
                        v-model='model.cd_orcamento_situacao'
                        :options='options.orcamento_situacao'
                        optionLabel='ds_situacao'
                        optionValue='id'
                        dataKey='id'
                    )

                .my-1(v-if='[28,30].includes(model.cd_relatorio)')
                    label.form-label Tipo de Caixa:
                    ProgressBar(v-if='waitingDominios' mode="indeterminate")
                    Dropdown(
                        v-model='model.tipo_caixa'
                        :options='options.tipo_caixa'
                        :showClear='true'
                        optionLabel='text'
                        optionValue='value'
                        dataKey='value'
                    )

                .my-1(v-if='![6,11,9,16,17,18,19,22,27].includes(model.cd_relatorio)')
                    .p-grid.p-fluid
                        .p-col-12(v-if="[40,53].includes(model.cd_relatorio)")
                            label.form-label Tipo de seleção de data
                            SelectButton(
                                v-model="tipo_data"
                                :options=" \
                                    model.cd_relatorio != 53 ? \
                                    options.options_tipo_data : ['Competência']")
                        .p-col-12(v-if="tipo_data=='Competência'")
                            label.form-label(for="monthpicker") Competência
                            Calendar(
                                id="monthpicker"
                                v-model="data_competencia"
                                view="month"
                                dateFormat="mm/yy"
                                :yearNavigator="true"
                                yearRange="2000:2030"
                                :locale="pt_br"
                                @input='formatarDataCompetencia()'
                            )

                        .p-col-6(v-if="tipo_data=='Intervalo'")
                            label.form-label Data inicial:
                            .p-inputgroup
                                Calendar(
                                    v-model='model.dt_inicial'
                                    dateFormat="dd/mm/yy"
                                    placeholder='DD/MM/AAAA'
                                    :locale="ptbr"
                                    :manualInput='false'
                                    @input='model.dt_final = null'
                                    :touchUI='windowInnerWidth < 576')
                                Button.p-button-danger(
                                    v-if="[24, 33, 46].includes(model.cd_relatorio)"
                                    icon='jam jam-rubber' type='button'
                                    v-tooltip.top="'Limpar'" @click='model.dt_inicial=null')
                        .p-col-6(v-if="tipo_data=='Intervalo'")
                            label.form-label Data final:
                            .p-inputgroup
                                Calendar(
                                    v-model='model.dt_final'
                                    dateFormat="dd/mm/yy"
                                    placeholder='DD/MM/AAAA'
                                    :minDate='model.dt_inicial'
                                    :maxDate="maxDate(28,30,36,48)"
                                    :locale="ptbr"
                                    :manualInput='false'
                                    :disabled='!model.dt_inicial'
                                    :touchUI='windowInnerWidth < 576')
                                Button.p-button-danger(
                                    v-if="[24, 33, 46].includes(model.cd_relatorio)"
                                    icon='jam jam-rubber' type='button'
                                    v-tooltip.top="'Limpar'" @click='model.dt_final=null')

                .my-1(v-if='[36, 43].includes(model.cd_relatorio)')
                    label.form-label Forma de pagamento:
                        MultiSelect( v-model='model.cd_forma_pagamento_list' :options='options.formas_pagamento'
                            optionLabel='text' optionValue='value' placeholder='- TODAS -' )

                .my-1(v-if='[33].includes(model.cd_relatorio)' style="border: 1px solid lightgrey; padding: 15px; border-radius: 15px")
                    .p-grid.p-fluid
                        .p-col-12.ta-center
                            label.form-label Intervalo para datas de pagamento:
                        .p-col-6
                            label.form-label Data inicial (pagamento):
                            .p-inputgroup
                                Calendar(
                                    v-model='model.dt_pagamento_inicial'
                                    dateFormat="dd/mm/yy"
                                    :locale="ptbr"
                                    :manualInput='false'
                                    :touchUI='windowInnerWidth < 576')
                                Button.p-button-danger(
                                    v-if="[33].includes(model.cd_relatorio)"
                                    icon='jam jam-rubber' type='button'
                                    v-tooltip.top="'Limpar'" @click='model.dt_pagamento_inicial=null')
                        .p-col-6
                            label.form-label Data final (pagamento):
                            .p-inputgroup
                                Calendar(
                                    v-model='model.dt_pagamento_final'
                                    dateFormat="dd/mm/yy"
                                    :locale="ptbr"
                                    :manualInput='false'
                                    :touchUI='windowInnerWidth < 576')
                                Button.p-button-danger(
                                    v-if="[33].includes(model.cd_relatorio)"
                                    icon='jam jam-rubber' type='button'
                                    v-tooltip.top="'Limpar'" @click='model.dt_pagamento_final=null')

                .my-1(v-if='[11].includes(model.cd_relatorio)')
                    label.form-label Data Vigência Final:
                    .p-inputgroup
                    Calendar(
                        v-model='model.dt_vigencia_final'
                        dateFormat="dd/mm/yy"
                        :locale="ptbr"
                        :manualInput='false'
                        :touchUI='windowInnerWidth < 576'
                    )
                        Button.p-button-danger(
                            v-if="[11].includes(model.cd_relatorio)"
                            icon='jam jam-rubber' type='button'
                            v-tooltip.top="'Limpar'" @click='model.dt_vigencia_final=null')
                .my-1(v-if='[6,18,19].includes(model.cd_relatorio)')
                    label.form-label Referência:
                    .p-inputgroup
                    Calendar(
                        v-model='model.dt_referencia'
                        dateFormat="yy/mm"
                        view="month"
                        :locale="ptbr"
                        :manualInput='false'
                        :touchUI='windowInnerWidth < 576'
                    )
                        Button.p-button-danger(
                            v-if="[6,18,19].includes(model.cd_relatorio)"
                            icon='jam jam-rubber' type='button'
                            v-tooltip.top="'Limpar'" @click='model.dt_referencia=null')
                .my-1(v-if="[24].includes(model.cd_relatorio)")
                    BuscarPaciente(
                        ref='buscarPaciente'
                        label='Pessoa Física'
                        :cancelarPopup='true'
                        :somenteTitular='true'
                        :hideCadastro='true')
                .my-1
                    label.form-label Tipo de arquivo:
                    CustomDropdown(
                        v-if="model.sintetico"
                        v-model='model.ie_tipo_relatorio'
                        :options='options.tipoarquivo_sem_csv'
                        :optionDisabled='isOptionDisabled'
                        optionLabel='text'
                        optionValue='value'
                        dataKey='value'
                    )
                    CustomDropdown(
                        v-else
                        v-model='model.ie_tipo_relatorio'
                        :options='options.tipoarquivo'
                        :optionDisabled='isOptionDisabled'
                        optionLabel='text'
                        optionValue='value'
                        dataKey='value'
                    )
                .my-1
                    label.form-label Senha de usuário:
                    InputText(type='password' v-model='model.ds_senha')
                .my-3
                    ProgressBar(v-if='waitingGerar' mode="indeterminate")
                    Button(v-else label='Gerar relatório' type='submit' @click='' )

</template>

<script>
import BuscarPaciente from './../Paciente/BuscarPaciente'
import Panel from 'primevue/panel'
import ProgressBar from 'primevue/progressbar'
import Dropdown from 'primevue/dropdown'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import Calendar from 'primevue/calendar'
import MultiSelect from 'primevue/multiselect'
import SelectButton from 'primevue/selectbutton'
import moment from 'moment'
import { Relatorio, Especialista, Estabelecimento, Agenda, Protocolo, Agendamento, Utils, Orcamento, UnidadesMedclub, DominioValor, MedEmpresa } from './../../middleware'
import { pCalendarLocale_ptbr } from './../../utils'
import Nps from '../../middleware/controllers/Nps'
import CustomDropdown from "@/components/CustomComponents/CustomDropdown";
import { Caixas } from '../../middleware'

export default {
    components: {CustomDropdown, BuscarPaciente, Panel, ProgressBar, Dropdown, InputText, Button, Calendar, MultiSelect, SelectButton },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            const routeName = vm.$route.path.split('/')[1]
            vm.isAtendimento = routeName === "relatorios-atendimento"
        })
    },
    mounted () {
        this.getRelatorios()
        this.getEstabelecimentos()
        this.getFiliaisMedclub()
        this.getProcedimentos()
        this.getEspecialidades()
        this.getEstados()
        this.getPerguntas()
        this.getEspecialistasWEstab()
        this.getFormasPagamento()
        this.getUnidades()
        this.getDominios()
        this.getMedEmpresas()
    },
    data () {
        return {
            waiting: false,
            waitingGerar: false,
            waitingEspecialistas: false,
            waitinCorrentista: false,
            ptbr: pCalendarLocale_ptbr,
            windowInnerWidth: window.innerWidth,
            isAtendimento: false,
            tipo_data: 'Intervalo',
            tipo_empresa: 'Med_Empresa',
            data_competencia: null,
            relatorios_intervalo_desde_inicio: [50], // Relatórios cujo intervalo de datas deve começar no início da existência do MedClub
            options: {
                formas_pagamento: [],
                cd_empresa: [],
                cd_pessoa_fisica:[],
                tipoarquivo: [
                    { value: 'pdf', text: 'PDF', disabledTo: [27, 28, 30, 31, 40, 44, 46, 48, 51, 52, 53] },
                    { value: 'csv', text: 'CSV', disabledTo: [37, 33, 44, 48] },
                    { value: 'xlsx', text: 'EXCEL', allowedTo: [14, 28, 30, 31, 32, 34, 36, 40, 41, 43, 44, 46, 48, 49, 51, 52, 53, 50] }
                ],
                tipoarquivo_sem_csv: [
                    { value: 'pdf', text: 'PDF', disabledTo: [27, 28, 30, 31, 44] },
                    { value: 'csv', text: 'CSV', disabledTo: [1, 44] },
                    { value: 'xlsx', text: 'EXCEL', allowedTo: [14, 28, 30, 31, 44] }
                ],
                tipoAgenda: [
                    { value: '', text: 'Todos' },
                    { value: 'E', text: 'Exames' },
                    { value: 'C', text: 'Consulta' },
                    { value: 'P', text: 'Procedimento' },
                    { value: 'L', text: 'Laboratório' },
                ],
                situacao: [
                    { value: 4, text: 'Emitidas' },
                    { value: 5, text: 'Executadas' },
                    { value: 6, text: 'Faturadas' }
                ],
                situacao_estorno: [
                    { value: 'PEN', text: 'Pendente' },
                    { value: 'APR', text: 'Aprovado' },
                    { value: 'CAN', text: 'Cancelado' }
                ],
                choiceBloqueios: [
                    { value: true, text: 'Ativados' },
                    { value: false, text: 'Desativados' },
                ],
                tipoProcedimento: [
                    { value: 'rx', text: 'Raio X' },
                    { value: 'us', text: 'Ultrassom' },
                    { value: 'tc', text: 'Tomografia' },
                    { value: 'rm', text: 'Ressonância' },
                    { value: 'xe', text: 'Exames Endoscópicos' },
                    { value: 'on', text: 'Otorrino/Neuro' },
                ],
                statusPagamento: [
                    { value: 'A', text: 'Faturado' },
                    { value: 'P', text: 'Pago' },
                    { value: 'E', text: 'Enviado' },
                    { value: 'T', text: 'TODOS' }
                ],
                filiaisMedclub:[],
                estabelecimentos:[],
                medEpresas: [],
                estabelecimentos_multi:[],
                especialistas:[],
                especialistasApp:[],
                procedimentos:[],
                especialidades:[],
                estados:[],
                orcamento_situacao:[],
                cidades:[],
                perguntas: [],
                formato: [{label: 'Analítico', value: false}, {label: 'Sintético', value: true}],
                totalizadores: [{label: 'Especialidades', value: 0}, {label: 'Estabelecimentos', value: 1}, {label: 'Ambos', value: 2}],
                unidades_medclub: [],
                tipo_caixa: [],
                tipo_estorno: [],
                situacao_aporte: [],
                faixas_etarias: [],
                options_tipo_empresa: ['Medclub', 'MedEMpresa'],
                options_tipo_data: ['Competência', 'Intervalo'] // Competência (mês) | Intervalo (data1 a data2)
            },
            model: {
                cd_relatorio: 1,
                ie_tipo_relatorio: 'pdf',
                ie_status: null,
                ie_situacao_list: [],
                ds_senha: '',
                ie_tipo_agenda: null,
                dt_inicial: moment()._d,
                dt_final: moment()._d,
                dt_pagamento_inicial: null,
                dt_pagamento_final: null,
                ie_status_pagamento: null,
                dt_referencia: null,
                cd_estabelecimento: null,
                cd_estabelecimento_list: [],
                cd_especialista: null,
                ie_tipo_procedimento: '',
                cd_procedimento: null,
                cd_pergunta: null,
                cd_espcialidade: null,
                cd_estado: null,
                cd_cidade: null,
                cd_orcamento_situacao: null,
                tipo_caixa: null,
                tipo_estorno: null,
                sintetico: false,
                totalizador: 0,
                cd_forma_pagamento_list: [],
                cd_unidade_list: [],
                cd_empresa:[],
                cd_pessoa_fisica:[]
            },
            default_model: {
                ie_tipo_relatorio: 'pdf',
                ie_status: null,
                ie_tipo_agenda: null,
                ds_senha: '',
                dt_inicial: moment()._d,
                dt_final: moment()._d,
                dt_pagamento_inicial: null,
                dt_pagamento_final: null,
                ie_status_pagamento: 'T',
                cd_estabelecimento: null,
                cd_estabelecimento_list: [],
                cd_especialista: null,
                ie_tipo_procedimento: '',
                cd_procedimento: null,
                cd_espcialidade: null,
                cd_pergunta: null,
                cd_estado: null,
                cd_cidade: null,
                cd_orcamento_situacao: null,
            },
            waiting_estabelecimento:false,
            waiting_filiais:false,
            waiting_especialistas:false,
            waiting_perguntas: false,
            waitingProcedimentos:false,
            waitingEspecialidades: false,
            waitingEstados:false,
            waitingOrcamentoSituacao:false,
            waitingUnidades:false,
            waitingDominios:false,
            waitingFaixasEtarias:false,
            pt_br: {
                firstDayOfWeek: 0,
                dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
                dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
                dayNamesMin: ["Do","Seg","Te","Qua","Qui","Se","Sa"],
                monthNames: [ "Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro" ],
                monthNamesShort: [ "Jan", "Fev", "Mar", "Abr", "Mai", "Jun","Jul", "Ago", "Set", "Out", "Nov", "Dez" ],
                today: 'Hoje',
                clear: 'Limpar',
                dateFormat: 'dd/mm/yy',
                weekHeader: 'Semana'
            }
        }
    },
    methods: {
        isOptionDisabled(option) {
            if (option.allowedTo && option.allowedTo.length) {
                return ! option.allowedTo.includes(this.model.cd_relatorio)
            }
            if (option.disabledTo && option.disabledTo.length) {
                return option.disabledTo.includes(this.model.cd_relatorio)
            }
            return false;
        },
        getMedEmpresas(){
            MedEmpresa.getEmpresas().then(response => {
                if(([200,201,204]).includes(response.status)){
                    this.options.cd_empresa = response.data.map(item => ({ value: item.id, label: item.nm_razao_social}))
                }
            })
        },
        getCorrentista(){
            this.waitinCorrentista = true
            MedEmpresa.getCorrentista({ cd_empresa: this.model.cd_empresa }).then(response => {
                this.waitinCorrentista = false
                if(([200,201,204]).includes(response.status)){
                    this.options.cd_pessoa_fisica = response.data.map(item => ({
                        value: item.cd_pessoa_fisica,
                        label: `${item.nm_pessoa_fisica} - ${item.nr_cpf.slice(0, 3)}.${item.nr_cpf.slice(3, 6)}.${item.nr_cpf.slice(6, 9)}-${item.nr_cpf.slice(9)}`
                    }))

                    console.log(this.options.cd_pessoa_fisica)
                }
            })
        },
        getRelatorios() {
            const requestFunction = this.isAtendimento ? Relatorio.getRelatoriosAtendimento : Relatorio.getList
            this.waiting = true

            requestFunction().then(response => {
                if (response.status === 200) {
                    this.options.tiporelatorio = []
                    response.data.forEach(item => {
                        this.options.tiporelatorio.push({
                            text: item.nr_relatorio + " - " +item.ds_relatorio, value: item.nr_relatorio
                        })
                    })
                }
                this.waiting = false
            })
        },
        getEstabelecimentos(){
            this.waiting_estabelecimento = true;
            Estabelecimento.findAllClean().then(response => {
                if (response.status === 200) {
                    response.data.forEach(e => {
                        this.options.estabelecimentos.push({ text: e.nm_fantasia, value: e.id })
                    })
                    this.options.estabelecimentos_multi = [...this.options.estabelecimentos]
                    this.options.estabelecimentos.unshift({ text: 'TODOS', value: null })
                }
                this.waiting_estabelecimento = false
            })
        },
        getFiliaisMedclub() {
            this.waiting_filiais = true;
            Estabelecimento.findAllClean({medclub_filiais: true}).then(response => {
                if (response.status === 200) {
                    this.options.filiaisMedclub = response.data.map(e => ({ text: e.nm_fantasia, value: e.id }))
                }
                this.waiting_filiais = false
            })
        },
        getPerguntas() {
            this.waiting_perguntas = true;
            Nps.findAll().then(response => {
                this.waiting_perguntas = false;
                if(response.status == 200) {
                    if (response.data.length) this.options.perguntas.push({ value: null, text:'- Selecione -' })
                    response.data.forEach(e => {
                        this.options.perguntas.push({ text: e.ds_pergunta, value: e.id })
                    })
                }
            })
        },
        getEspecialistasWEstab() {
            this.options.especialistasApp = []
            this.waitingEspecialistas = true;
            Especialista.getAll().then(response => {
                this.waitingEspecialistas = false;
                if (response.status == 200) {
                    let mem = {}
                    response.data.forEach(e => {
                        if (! mem[e.id]) {
                            mem[e.id] = true
                            this.options.especialistasApp.push({ text: e.nm_especialista, value: e.id })
                        }
                    })
                    this.options.especialistasApp.sort((a, b) => a.text > b.text ? 1 : -1)
                    this.options.especialistasApp.unshift({ text: 'TODOS', value: null })
                } else {
                    this.$toast.error(response.data.detail, { duration: 3000 })
                }
            })
        },
        getFormasPagamento() {
            Caixas.getFormasPagamento().then(response => {
				this.waiting = false
				if (response.status == 200) {
					if (response.data.length) {
						response.data.forEach(elem => {
							this.options.formas_pagamento.push({ value: elem.id, text: elem.nm_forma_pagamento })
						})
						this.model.cd_forma_pagamento_list = []
					}
				}
			})
        },
        getEspecialistas(){
            this.options.especialistas = []
            this.waiting_especialistas = true
            Agenda.getEspecialistasAgendados({cd_estabelecimento:this.model.cd_estabelecimento}).then(response => {
                this.waiting_especialistas = false
                if (response.status == 200) {
                    let mem = {}
                    response.data.forEach(e => {
                        if (! mem[e.cd_especialista]) {
                            mem[e.cd_especialista] = true
                            this.options.especialistas.push({ text: e.nm_especialista, value: e.cd_especialista })
                        }
                    })
                    this.options.especialistas.sort((a, b) => a.text > b.text ? 1 : -1)
                    this.options.especialistas.unshift({ text: 'TODOS', value: null })
                } else {
                    this.$toast.error(response.data.detail, { duration: 3000 })
                }
            })
        },
        getProcedimentos () {

            let params = {
                ie_tipo_agenda: this.model.ie_tipo_agenda ? this.model.ie_tipo_agenda : 'E',
                ie_telemedicina: 0
            }

            this.waitingProcedimentos = true
            Agendamento.getProcedimentosCadastro(params).then(response => {
                this.waitingProcedimentos = false
                this.options.procedimentos = []
                if (response.status == 200) {
                    if (response.data.length) this.options.procedimentos.push({ value: null, text:'- Selecione -', textAlt: '- Selecione -' })
                    response.data.forEach(procedimento => {
                        this.options.procedimentos.push({
                            value: procedimento.id,
                            text: `${ procedimento.cd_procedimento } - ${ procedimento.ds_procedimento } / ${ procedimento.nm_procedimento }`,
                            textAlt: `${ procedimento.cd_procedimento } - ${ procedimento.ds_procedimento }`,
                            nm_procedimento: procedimento.nm_procedimento,
                            id: procedimento.id
                        })
                    })
                }
            })
        },
        getEstados(){
            Utils.getUFs().then(response => {
                this.options.estados.push({ value: null, text: '- Selecione -', textAlt: '- Selecione -' })
                if (response.status == 200) this.options.estados = response.data
            })
        },
        getOrcamentoSituacao(){
            Orcamento.orcamentoSituacao().then(response => {
                this.options.orcamento_situacao.push({ value: null, text: '- Selecione -', textAlt: '- Selecione -' })
                if (response.status == 200){
                    this.options.orcamento_situacao = response.data
                    this.waitingOrcamentoSituacao = false
                }
            })
        },
        getCidades(){
            this.waitingEstados = true
            this.waitingCidades = true
            if (this.model.cd_estado)
                this.options.municipiosEndereco = []
            Utils.getMunicipiosEstadoId(this.model.cd_estado).then(response => {
                this.options.municipiosEndereco.push({ value: null, text: '- Selecione -' })
                if (response.status == 200)
                    response.data.forEach(mun => this.options.cidades.push({
                        value: mun.id,
                        text: mun.nm_cidade,
                        obj: mun
                    }))
                this.waitingEstados = false
            })

        },
        getEspecialidades () {
            this.waitingEspecialidades = true
            this.options.cd_especialidade = []
            Protocolo.getEspecialidades().then(response => {
                if (response.status == 200) {
                    response.data.forEach(e => {
                        this.options.cd_especialidade.push({ text: e.nm_especialidade, value: e.id })
                    })
                }
                this.waitingEspecialidades = false
            })
        },
        getUnidades (){
            this.waitingUnidades = true
            UnidadesMedclub.findAllSala({ ie_tipo:'ATD' }).then(response=>{
                if (response.status == 200) {
                    response.data.forEach(e => {
                        this.options.unidades_medclub.push({text: e.nm_sala, textAlt1: e.nm_setor, textAlt2: e.nm_unidade, value: e.id})
                    })
                }
                this.waitingUnidades = false
            })
        },
        getDominios() {
            this.waitingDominios = true
            DominioValor.findAll({ds_mnemonico: ['TIPO_CAIXA', 'TIPO_SOLICITACAO', 'SITUACAO_APORTE']}).then(response => {
                if (response.status == 200){
                    response.data['TIPO_CAIXA'].valores.forEach((un) => {
                        if(un.ie_valor != 'F') {
                            this.options.tipo_caixa.push({ value: un.ie_valor, text: un.ds_valor})
                        }
                    });

                    response.data['TIPO_SOLICITACAO'].valores.forEach((un) => {
                        this.options.tipo_estorno.push({ value: un.ie_valor, text: un.ds_valor})
                    });

                    response.data['SITUACAO_APORTE'].valores.forEach((un) => {
                        this.options.situacao_aporte.push({ value: un.ie_valor, text: un.ds_valor})
                    });
                }
                this.waitingDominios = false
            })
        },
        getFaixasEtarias(){
            let params = {
                cd_empresa: this.model.cd_empresa,
                ie_vigente: true
            }
            this.options.faixas_etarias = []
            this.waitingFaixasEtarias = true
            MedEmpresa.listFaixasEtarias(params).then(response => {
                this.waitingFaixasEtarias = false
                if (response.status == 200){
                    response.data.forEach((obj) => {
                        this.options.faixas_etarias.push({
                            text: `${obj.nr_idade_inicial} a ${obj.nr_idade_final}`,
                            value: obj.id
                        })
                    });
                }
            })
        },
        resetModel(){
            let keys = Object.keys(this.default_model);
            keys.forEach(key => this.model[key] = this.default_model[key]);

            this.options.tipoarquivo.some(option => {
                this.model.ie_tipo_relatorio = option.value;
                return !this.isOptionDisabled(option);
            });

            this.options.especialistas = [];

            if (this.model.cd_relatorio == 53) {
                this.tipo_data = 'Competência'
            }

            if (this.relatorios_intervalo_desde_inicio.includes(this.model.cd_relatorio)){
                this.model.dt_inicial = moment('2020-07-01').toDate();
            }
        },
        carregarFiltros(value){
            if([].includes(value)){
                this.waitingOrcamentoSituacao = true;
                this.getOrcamentoSituacao();
            }
        },
        onFormSubmit () {
            let dataSend = {}
            Object.keys(this.model).forEach(key => {if (this.model[key] !== null) dataSend[key] = this.model[key]})

            if (dataSend.dt_inicial) dataSend.dt_inicial = moment(dataSend.dt_inicial).format('YYYY-MM-DD')
            if (dataSend.dt_final) dataSend.dt_final = moment(dataSend.dt_final).format('YYYY-MM-DD')

            if (dataSend.dt_pagamento_inicial) dataSend.dt_pagamento_inicial = moment(dataSend.dt_pagamento_inicial).format('YYYY-MM-DD')
            if (dataSend.dt_pagamento_final) dataSend.dt_pagamento_final = moment(dataSend.dt_pagamento_final).format('YYYY-MM-DD')

            if (this.$refs.buscarPaciente) dataSend.cd_paciente = this.$refs.buscarPaciente.cd_pessoa_fisica
            dataSend.nr_relatorio = this.model.cd_relatorio

            this.waitingGerar = true
            Relatorio.createGenerico(dataSend).then(response => {
                this.waitingGerar = false
                if ([400, 403].includes(response.status)) {
                    if (response.data.detail)
                        this.$toast.error(response.data.detail, { duration: 3000 })
                }else if (response.status == 200) {
                    this.$router.go(-1)
                    this.$toast.info('Relatório em processamento.', { duration: 4000 })
                } else if (response.status == 201) {
                    this.$router.go(-1)
                    this.$toast.success('Relatório gerado com sucesso.', { duration: 4000 })
                }
            })
        },
        formatarDataCompetencia() {
            let data_competencia = new Date(this.data_competencia);

            let primeiro_dia = new Date(data_competencia.getFullYear(), data_competencia.getMonth(), 1);
            let ultimo_dia = new Date(data_competencia.getFullYear(), data_competencia.getMonth() + 1, 0);

            this.model.dt_inicial = primeiro_dia;
            this.model.dt_final = ultimo_dia;
        },
        maxDate(...relatorios) {
            return relatorios.includes(this.model.cd_relatorio) ? moment(this.model.dt_inicial).add(3, 'M')._d : null
        }
    }
}
</script>
